<template>
  <button
    class="
      inline-flex
      items-center
      px-4
      py-2
      border border-transparent
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-white
      bg-yellow-600
      hover:bg-yellow-700
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
